var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-container",
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "12", md: "12" } },
                [
                  _c(
                    "v-btn",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.cancel(false)
                        },
                      },
                    },
                    [_vm._v(" cancel ")]
                  ),
                  _vm.canEditSetupTables
                    ? _c(
                        "v-btn",
                        {
                          staticClass: "float-right white--text",
                          attrs: {
                            color: "green",
                            disabled: _vm.$v.form.$invalid,
                          },
                          on: { click: _vm.onSubmit },
                        },
                        [_vm._v(" save ")]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-card",
        [
          _c(
            "v-form",
            {
              attrs: { readonly: _vm.value.id > 0 && !_vm.canEditSetupTables },
            },
            [
              _c(
                "v-container",
                [
                  _c(
                    "v-row",
                    { staticClass: "my-0 py-0", attrs: { dense: "" } },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "4" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              value: _vm.value.sequenceNumber,
                              type: "number",
                              label: "Sequence Number",
                              required: "",
                              min: "0",
                              "error-messages": _vm.handleErrors(
                                _vm.$v.form.sequenceNumber
                              ),
                            },
                            on: {
                              blur: function ($event) {
                                return _vm.$v.form.sequenceNumber.$touch()
                              },
                            },
                            model: {
                              value: _vm.$v.form.sequenceNumber.$model,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.$v.form.sequenceNumber,
                                  "$model",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "$v.form.sequenceNumber.$model",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "4" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              value: _vm.value.vehicleCondition,
                              label: "Condition",
                              required: "",
                              "error-messages": _vm.handleErrors(
                                _vm.$v.form.vehicleCondition
                              ),
                            },
                            on: {
                              blur: function ($event) {
                                return _vm.$v.form.vehicleCondition.$touch()
                              },
                            },
                            model: {
                              value: _vm.$v.form.vehicleCondition.$model,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.$v.form.vehicleCondition,
                                  "$model",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "$v.form.vehicleCondition.$model",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "4" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              value: _vm.value.charge,
                              type: "number",
                              label: "Charge",
                              required: "",
                              min: "0",
                              "error-messages": _vm.handleErrors(
                                _vm.$v.form.charge
                              ),
                            },
                            on: {
                              blur: function ($event) {
                                return _vm.$v.form.charge.$touch()
                              },
                            },
                            model: {
                              value: _vm.$v.form.charge.$model,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.$v.form.charge,
                                  "$model",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "$v.form.charge.$model",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }