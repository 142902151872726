var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("setup-table", {
        attrs: {
          title: `Vehicle Condition Rate`,
          items: _vm.items,
          headers: _vm.headers,
          loading: _vm.loading,
          clearItem: !_vm.showForm || !_vm.editedItem.id,
        },
        on: {
          createItem: function ($event) {
            return _vm.createItem()
          },
          editItem: _vm.editItem,
          deleteItems: _vm.deleteItems,
        },
        scopedSlots: _vm._u(
          [
            _vm.showForm
              ? {
                  key: "itemForm",
                  fn: function () {
                    return [
                      _c("vehicle-condition-rates-form", {
                        ref: "vehicleConditionRatesForm",
                        attrs: { value: _vm.editedItem },
                        on: { submit: _vm.saveItem, closeForm: _vm.closeForm },
                      }),
                    ]
                  },
                  proxy: true,
                }
              : null,
            {
              key: `item.charge`,
              fn: function ({ item }) {
                return [
                  _c("p", { staticClass: "mb-0" }, [
                    _vm._v("$" + _vm._s(item.charge)),
                  ]),
                ]
              },
            },
          ],
          null,
          true
        ),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }